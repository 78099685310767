*, :before, :after {
  box-sizing: border-box;
}

body {
  color: #333;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  margin: 0;
  padding: 0;
  font-family: Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Helvetica Neue, sans-serif;
  font-size: 13px;
}

button, select {
  font-family: inherit;
}

input {
  font-size: inherit;
  color: inherit;
  padding: 8px;
  font-family: inherit;
}

a {
  color: var(--blue-50);
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

h1 {
  text-align: center;
  font-size: 42px;
  font-weight: 300;
}

h1 a {
  color: inherit;
  text-decoration: none;
}

h1 a:hover {
  color: var(--blue-50);
}

h3 {
  padding-top: 20px;
  font-size: 18px;
  font-weight: 600;
}

code {
  color: #e83e8c;
  font-family: var(--monospace-font);
}

::-webkit-scrollbar {
  opacity: 1;
  background-color: #0000;
  width: .8em;
}

::-webkit-scrollbar-thumb:window-inactive {
  background: #e5e5e5;
  border-radius: .8em;
}

::-webkit-scrollbar-thumb {
  background: #e5e5e5;
  border-radius: .8em;
}

:root {
  --monospace-font: "Fira Code", SFMono-Regular, "SF Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --magenta-50: #ff1ad9;
  --magenta-60: #ed00b5;
  --magenta-70: #b5007f;
  --magenta-80: #7d004f;
  --magenta-90: #440027;
  --purple-50: #9400ff;
  --purple-60: #8000d7;
  --purple-70: #6200a4;
  --purple-80: #440071;
  --purple-90: #25003e;
  --blue-40: #45a1ff;
  --blue-50: #0a84ff;
  --blue-50-a30: #0a84ff4d;
  --blue-60: #0060df;
  --blue-70: #003eaa;
  --blue-80: #002275;
  --blue-90: #000f40;
  --teal-50: #00feff;
  --teal-60: #00c8d7;
  --teal-70: #008ea4;
  --teal-80: #005a71;
  --teal-90: #002d3e;
  --green-50: #30e60b;
  --green-60: #12bc00;
  --green-70: #058b00;
  --green-80: #006504;
  --green-90: #003706;
  --yellow-50: #ffe900;
  --yellow-60: #d7b600;
  --yellow-70: #a47f00;
  --yellow-80: #715100;
  --yellow-90: #3e2800;
  --red-50: #ff0039;
  --red-60: #d70022;
  --red-70: #a4000f;
  --red-80: #5a0002;
  --red-90: #3e0200;
  --orange-50: #ff9400;
  --orange-60: #d76e00;
  --orange-70: #a44900;
  --orange-80: #712b00;
  --orange-90: #3e1300;
  --grey-10: #f9f9fa;
  --grey-20: #ededf0;
  --grey-30: #d7d7db;
  --grey-40: #b1b1b3;
  --grey-50: #737373;
  --grey-60: #4a4a4f;
  --grey-70: #38383d;
  --grey-80: #2a2a2e;
  --grey-90: #0c0c0d;
  --grey-90-a05: #0c0c0d0d;
  --grey-90-a10: #0c0c0d1a;
  --grey-90-a20: #0c0c0d33;
  --grey-90-a30: #0c0c0d4d;
  --grey-90-a40: #0c0c0d66;
  --grey-90-a50: #0c0c0d80;
  --grey-90-a60: #0c0c0d99;
  --grey-90-a70: #0c0c0db3;
  --grey-90-a80: #0c0c0dcc;
  --grey-90-a90: #0c0c0de6;
  --ink-70: #363959;
  --ink-80: #202340;
  --ink-90: #0f1126;
  --white-100: #fff;
}

.MS91xW_aside {
  background: #f9f9fa;
  border-right: 1px solid #00000005;
  flex-shrink: 0;
  width: 320px;
  padding: 12px;
  font-size: 14px;
  font-weight: 500;
  overflow: auto;
}

.MS91xW_aside ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.MS91xW_navLink {
  color: inherit;
  border-radius: 3px;
  align-items: center;
  padding: 3px;
  text-decoration: none;
  display: flex;
}

.MS91xW_navLink > span {
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.MS91xW_navLink.MS91xW_active, .MS91xW_navLink:hover {
  background-color: #ededf0;
}

.MS91xW_navLink:hover {
  color: #0a84ff;
}

.MS91xW_header {
  color: #777c;
  padding: 3px;
  font-weight: 600;
}

.MS91xW_spacer {
  padding: 8px;
}

.MS91xW_main {
  flex-grow: 1;
  position: relative;
  overflow: auto;
}

.MS91xW_logo {
  letter-spacing: .5px;
  border-radius: 0;
  width: min-content;
  margin-bottom: 8px;
  padding-block: 3px 5px;
  padding-inline-end: 14px;
  font-family: Zilla Slab, Nunito Sans, -apple-system, BlinkMacSystemFont, Segoe UI, Ubuntu, Helvetica Neue, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  position: relative;
  color: #333 !important;
  background: none !important;
  text-decoration: none !important;
}

.MS91xW_logo:hover {
  color: #0a84ff !important;
}

.MS91xW_logo .MS91xW_icon {
  width: 1em;
  height: 1em;
  margin-inline-end: 6px;
}

.MS91xW_icon {
  vertical-align: middle;
  width: 20px;
  height: 20px;
  margin-right: 5px;
  display: inline-block;
}

.MS91xW_icon-logo {
  background-image: url("logo.057ddeaa.svg");
  background-size: contain;
}

.MS91xW_icon-calendar {
  background-image: url("001-calendar.c83f98f1.svg");
}

.MS91xW_icon-calendar2 {
  background-image: url("calendar2.17c0b8e6.svg");
}

.MS91xW_icon-graph {
  background-image: url("002-presentation.1ccd2612.svg");
}

.MS91xW_icon-user {
  background-image: url("003-user.85e442bd.svg");
}

.MS91xW_icon-inbox {
  background-image: url("004-inbox.6b965c06.svg");
}

.MS91xW_icon-rgb {
  background-image: url("rgb.d512081f.svg");
}

.MS91xW_icon-alert {
  background-image: url("alert.66782602.svg");
}

.MS91xW_icon-report {
  background-image: url("report.e7f650c9.svg");
}

.MS91xW_icon-info {
  background-image: url("info.3c33edcb.svg");
}

.MS91xW_icon-refresh {
  background-image: url("refresh.d099b3be.svg");
}

.MS91xW_icon-warning {
  background-image: url("warning.075b8745.svg");
}

.MS91xW_icon-balloons {
  background-image: url("balloons.cbd0557a.svg");
}

.MS91xW_icon-up-arrow {
  background-image: url("up-arrow.35ec1a16.svg");
}

.MS91xW_icon-up-arrow-yellow {
  background-image: url("up-arrow-yellow.d14cc3da.svg");
}

.MS91xW_icon-pocket {
  background-image: url("pocket.57c9c1bb.svg");
}

.MS91xW_icon-hourglass {
  background-image: url("hourglass.2c2c4d0c.svg");
}

.MS91xW_icon-settings {
  background-image: url("settings.b66dd86a.svg");
}

.MS91xW_icon-settings-circle {
  background-image: url("settings-circle.75a0ace2.svg");
}

.MS91xW_icon-stethoscope {
  background-image: url("stethoscope.67c09cdb.svg");
  background-size: contain;
}

.MS91xW_icon-capacity {
  background-image: url("capacity.4b06da21.svg");
  background-size: contain;
}

.MS91xW_icon-jira {
  background-image: url("jira.4c07e72c.svg");
  background-size: contain;
}

.j2UynG_bugTable {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
}

.j2UynG_bugTable + .j2UynG_bugTable {
  margin-top: 20px;
}

.j2UynG_bugTable :where(thead, tbody, tfoot) > tr:not(:last-child) > :where(th, td), .j2UynG_bugTable tr:not(:last-child) > :where(th, td), .j2UynG_bugTable :where(thead, tbody, tfoot):not(:last-child) > tr:last-child > :where(th, td) {
  border-bottom: 1px solid #ccc;
}

.j2UynG_th {
  white-space: nowrap;
  text-align: left;
  padding: 10px;
  font-weight: normal;
}

.j2UynG_labels .j2UynG_th {
  color: var(--grey-50);
  background: var(--grey-10);
  padding: 6px 10px;
  font-size: 12px;
  font-weight: 600;
}

.j2UynG_editor .j2UynG_th {
  background: var(--grey-10);
  padding: 8px 10px;
}

.j2UynG_editorCompact .j2UynG_th {
  background: var(--grey-10);
  padding: 6px 10px;
}

.j2UynG_td {
  vertical-align: middle;
  padding: 10px;
}

.j2UynG_td a {
  color: var(--blue-50);
  text-decoration: none;
}

.j2UynG_td a:hover {
  text-decoration: underline;
}

.j2UynG_td.j2UynG_summaryColumn {
  word-wrap: anywhere;
  font-size: 14px;
}

.j2UynG_td.j2UynG_summaryColumn a {
  color: inherit;
}

.j2UynG_td.j2UynG_idColumn {
  font-family: var(--monospace-font);
  white-space: nowrap;
  width: 1%;
  font-size: 14px;
  font-weight: bold;
}

.j2UynG_td.j2UynG_idColumn .j2UynG_idSpacer {
  user-select: none;
  font-size: .5em;
}

.j2UynG_td.j2UynG_idColumn .j2UynG_warning {
  color: #e08529;
  text-decoration: underline .05em wavy;
}

.j2UynG_td.j2UynG_idColumn .j2UynG_warning:hover {
  text-decoration: none;
}

.j2UynG_td.j2UynG_last_change_timeColumn {
  white-space: nowrap;
}

.j2UynG_td > img {
  display: block;
}

.j2UynG_resolved {
  color: #00000080;
}

.j2UynG_resolved a {
  color: inherit;
  text-decoration: line-through;
}

.j2UynG_resolved .j2UynG_tagList li {
  opacity: .5;
}

.j2UynG_mine {
  background-color: #005ac71a;
}

.j2UynG_tagList {
  margin-left: 5px;
  padding: 0;
  list-style: none;
  display: inline;
}

.j2UynG_tagList li {
  color: var(--blue-40);
  font-size: 11px;
  font-family: var(--monospace-font);
  border: 1px solid;
  border-radius: 3px;
  padding: 2px 5px;
  display: inline-block;
}

.j2UynG_tagList li + li {
  margin-left: 3px;
}

.j2UynG_bulkColumn {
  width: 1%;
}

.j2UynG_editorGroup {
  display: inline;
}

.j2UynG_editorGroup + .j2UynG_editorGroup {
  margin-left: 20px;
}

.j2UynG_editorType {
  align-items: center;
  height: 20px;
  display: flex;
}

.j2UynG_leftEditorGroup {
  flex-grow: 1;
}

.j2UynG_emptyState {
  text-align: center;
  background: var(--grey-20);
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
}

.j2UynG_statusLabel {
  color: #fff;
  background: var(--blue-40);
  font-size: 11px;
  font-weight: bold;
  font-family: var(--monospace-font);
  border-radius: 3px;
  padding: 2px 5px;
  display: inline-block;
}

.j2UynG_statusLabel.j2UynG_status-verified {
  color: #fff;
  background-color: #00b58f;
  border: 0;
}

.i6C_PW_primaryButton {
  color: #fff;
  background: var(--blue-50);
  cursor: default;
  border: 0;
  border-radius: 3px;
  padding: 5px 8px;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  display: inline-block;
  text-decoration: none !important;
}

.i6C_PW_primaryButton:hover {
  color: #fff;
  background: var(--blue-40);
}

.i6C_PW_plainLink {
  color: inherit;
  text-decoration: none;
}

.i6C_PW_plainLink:hover {
  text-decoration: underline;
}

.i6C_PW_headerButton {
  vertical-align: middle;
  font-size: 14px;
}

.i6C_PW_smallInput {
  background-color: #fff;
  border: 1px solid #0000004d;
  border-radius: 3px;
  padding: 5px 8px;
}

.i6C_PW_codeSnippet {
  background: var(--grey-10);
  font-family: var(--monospace-font);
  border: 1px solid #0000001a;
  padding: 8px;
}

.dOPdlq_containerOpen, .dOPdlq_containerClosed {
  width: 250px;
  line-height: 1.4;
}

@media (prefers-reduced-motion: no-preference) {
  .dOPdlq_containerOpen, .dOPdlq_containerClosed {
    transition: margin .25s;
  }
}

@media (width <= 1210px) {
  .dOPdlq_containerOpen, .dOPdlq_containerClosed {
    display: none;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .dOPdlq_containerClosed {
    visibility: collapse;
    margin-right: -250px;
    transition: margin .25s, visibility 0s .25s;
  }
}

@media (prefers-reduced-motion: reduce) {
  .dOPdlq_containerClosed {
    display: none;
  }
}

.dOPdlq_p1, .dOPdlq_p2, .dOPdlq_p3, .dOPdlq_p4, .dOPdlq_p5, .dOPdlq_s1, .dOPdlq_s2, .dOPdlq_s3, .dOPdlq_s4, .dOPdlq_s5, .dOPdlq_sm, .dOPdlq_md, .dOPdlq_lg, .dOPdlq_xl, .dOPdlq_total, .dOPdlq_n\/a, .dOPdlq_legendButton {
  border: 1px solid;
  border-radius: 3px;
  padding: 0 3px;
  font-weight: 800;
}

.dOPdlq_p1:where(button), .dOPdlq_p2:where(button), .dOPdlq_p3:where(button), .dOPdlq_p4:where(button), .dOPdlq_p5:where(button), .dOPdlq_s1:where(button), .dOPdlq_s2:where(button), .dOPdlq_s3:where(button), .dOPdlq_s4:where(button), .dOPdlq_s5:where(button), .dOPdlq_sm:where(button), .dOPdlq_md:where(button), .dOPdlq_lg:where(button), .dOPdlq_xl:where(button), .dOPdlq_total:where(button), .dOPdlq_n\/a:where(button), .dOPdlq_legendButton:where(button) {
  cursor: pointer;
}

.dOPdlq_p1 {
  color: #fff;
  background: #0083ff;
  border-color: #0000;
}

.dOPdlq_p2 {
  color: #fff;
  background: #ac6bc3;
  border-color: #0000;
}

.dOPdlq_p3 {
  color: #bf6fd4;
  background: none;
}

.dOPdlq_p4, .dOPdlq_p5 {
  color: #7e7e7e;
  background: none;
}

.dOPdlq_s1 {
  color: #fff;
  background: #ff4d4d;
  border-color: #0000;
}

.dOPdlq_s2 {
  color: #fff;
  background: #ff9d4d;
  border-color: #0000;
}

.dOPdlq_s3 {
  color: #ff4d4d;
  background: none;
}

.dOPdlq_s4, .dOPdlq_s5 {
  color: #7e7e7e;
  background: none;
}

.dOPdlq_sm {
  color: #45a1ff;
  background: none;
}

.dOPdlq_md {
  color: #fff;
  background: #79de54;
  border-color: #0000;
}

.dOPdlq_lg {
  color: #fff;
  background: #ff9d4d;
  border-color: #0000;
}

.dOPdlq_xl {
  color: #fff;
  background: #ff4d4d;
  border-color: #0000;
}

.dOPdlq_total {
  color: inherit;
  background: none;
}

.dOPdlq_n\/a {
  color: #7e7e7e;
  border-color: #7e7e7e;
}

.dOPdlq_-- {
  background: none;
  border: none;
}

.dOPdlq_legendButton {
  color: #fff;
  background: #45a1ff;
  border-color: #0000;
}

.dOPdlq_sidebarToggle {
  cursor: pointer;
  background: none;
  border: 0;
  height: 20px;
  padding-top: 40px;
  position: absolute;
  right: 10px;
}

@media (width <= 1210px) {
  .dOPdlq_sidebarToggle {
    display: none;
  }
}

.dOPdlq_innerBox {
  background: #f9f9fa;
  width: 250px;
  padding-block: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.dOPdlq_title {
  margin-inline: 20px;
}

.dOPdlq_scrollbox {
  max-height: 100%;
  padding: 0 20px 50px;
  overflow: auto;
}

.dOPdlq_iconArrowhead {
  vertical-align: middle;
  background-image: url("arrow-r-dark.ee739ed1.svg");
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
}

.dOPdlq_iconOverflow {
  vertical-align: middle;
  background-image: url("arrow-l-dark.745d1825.svg");
  width: 16px;
  height: 16px;
  margin-right: 5px;
  display: inline-block;
}

.u3T-ma_loader {
  --loader-color: var(--grey-30);
  color: var(--loader-color);
  text-indent: -9999em;
  margin: 88px auto;
  font-size: 11px;
  animation-delay: -.16s;
  position: relative;
  transform: translateZ(0);
}

.u3T-ma_loader, .u3T-ma_loader:before, .u3T-ma_loader:after {
  background: var(--loader-color);
  width: 1em;
  height: 4em;
  animation: 1s ease-in-out infinite u3T-ma_load1;
}

.u3T-ma_loader:before, .u3T-ma_loader:after {
  content: "";
  position: absolute;
  top: 0;
}

.u3T-ma_loader:before {
  animation-delay: -.32s;
  left: -1.5em;
}

.u3T-ma_loader:after {
  left: 1.5em;
}

.u3T-ma_loaderContainer {
  animation: 1s u3T-ma_fadein1;
}

@keyframes u3T-ma_load1 {
  0%, 80%, 100% {
    height: 4em;
    box-shadow: 0 0;
  }

  40% {
    height: 5em;
    box-shadow: 0 -2em;
  }
}

@keyframes u3T-ma_fadein1 {
  from {
    opacity: 0;
  }
}

.g5nwzq_miniLoader {
  --loader-color: var(--ink-90);
  --load-ping-timing-function: cubic-bezier(.075, .82, .165, 1);
  text-indent: -9999em;
  width: 24px;
  height: 24px;
  margin: 2em;
  transition: opacity .5s;
  display: block;
  position: fixed;
  top: 0;
  left: 320px;
}

.g5nwzq_miniLoader[hidden] {
  opacity: 0;
}

.g5nwzq_miniLoader[stopped] {
  display: none;
}

.g5nwzq_miniLoader span {
  width: 24px;
  height: 24px;
  display: block;
}

.g5nwzq_miniLoader span:before, .g5nwzq_miniLoader span:after {
  content: "";
  border: 2px solid var(--loader-color);
  opacity: 0;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: auto;
  animation: 1.5s cubic-bezier(.075, .82, .165, 1) infinite g5nwzq_miniLoader-1;
  position: absolute;
  inset: 0;
}

.g5nwzq_miniLoader span:after {
  animation: 1.5s cubic-bezier(.075, .82, .165, 1) .25s infinite g5nwzq_miniLoader-2;
}

@keyframes g5nwzq_miniLoader-1 {
  0% {
    opacity: 1;
    scale: 0;
  }

  to {
    opacity: 0;
    scale: 1.5;
  }
}

@keyframes g5nwzq_miniLoader-2 {
  0% {
    opacity: 1;
    scale: 0;
  }

  to {
    opacity: 0;
    scale: 1;
  }
}

.zFcJna_container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 40px;
}

.zFcJna_heading {
  margin-bottom: 20px;
}

.zFcJna_subHeading {
  text-align: center;
}

.PEqzHq_container {
  padding: 32px 0;
}

.PEqzHq_unpaddedContainer {
  padding: 10px 0 15px;
}

.PEqzHq_innerWrapper {
  text-align: center;
  color: #777;
  position: relative;
}

.PEqzHq_completionBar {
  background: var(--grey-20);
  border-radius: 10px;
  height: 20px;
  margin: 10px auto;
  position: relative;
  overflow: hidden;
}

.PEqzHq_resolvedLabel, .PEqzHq_assignedLabel {
  color: #5594fb;
}

.PEqzHq_assignedLabel {
  font-size: .9em;
}

.PEqzHq_bugsBar {
  background: var(--blue-50);
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PEqzHq_dateBar {
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.PEqzHq_dateBar.PEqzHq_ahead {
  opacity: .3;
  z-index: 1;
  background: var(--ink-70);
}

.PEqzHq_dateBar.PEqzHq_behind {
  background: var(--ink-70);
}

.PEqzHq_dateLine {
  background-color: var(--ink-70);
  width: 2px;
  position: absolute;
  top: -4px;
  bottom: 0;
}

.PEqzHq_marker {
  cursor: default;
  margin-left: -28px;
  padding: 8px;
  position: absolute;
}

.PEqzHq_marker:before {
  content: "";
  width: 0;
  height: 0;
  position: absolute;
  left: 24px;
}

.PEqzHq_marker.PEqzHq_top {
  background-color: var(--blue-50);
  color: #fff;
  margin-top: 8px;
  top: 100%;
}

.PEqzHq_marker.PEqzHq_top:before {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  border-bottom: 5px solid var(--blue-50);
  bottom: 100%;
}

.PEqzHq_marker.PEqzHq_bottom {
  background-color: var(--ink-70);
  color: #fff;
  margin-bottom: 8px;
  bottom: 100%;
}

.PEqzHq_marker.PEqzHq_bottom:before {
  border-left: 5px solid #0000;
  border-right: 5px solid #0000;
  border-top: 5px solid var(--ink-70);
  top: 100%;
}

.SjlX-a_iterationHeading {
  align-items: center;
  display: flex;
}

.SjlX-a_iterationHeading .SjlX-a_iterationSpacer {
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
  display: flex;
}

.SjlX-a_iterationHeading .SjlX-a_iterationSpacer:first-child {
  justify-content: flex-start;
}

.SjlX-a_iterationHeading .SjlX-a_iterationSpacer:last-child {
  justify-content: flex-end;
}

.SjlX-a_iterationHeading .SjlX-a_iterationSpacer .SjlX-a_iterationArrow {
  padding: .25em .6em;
  font-size: .5em;
  text-decoration: none;
}

.ve33Va_container {
  max-width: 1600px;
  margin: 0 auto;
  padding: 20px 40px;
}

.ve33Va_wrapper {
  display: flex;
}

.ve33Va_mainColumn {
  flex: 3;
  margin-right: 20px;
}

.ve33Va_sideColumn {
  flex: 2;
}

.ve33Va_settingsBtn {
  background: url("settings.b66dd86a.svg");
  border: 0;
  width: 16px;
  height: 16px;
  position: relative;
  top: -19px;
}

.ve33Va_settingsBtn:hover {
  cursor: pointer;
}

.ve33Va_settingSection {
  text-align: center;
}

.p1eSsq_container {
  max-width: 640px;
  margin: 0 auto;
  padding: 20px 40px;
  font-size: 14px;
}

.UuBI7q_button {
  background: url("copy.282d9f31.svg");
  border: 0;
  width: 16px;
  height: 16px;
  position: relative;
  top: -4px;
}

.UuBI7q_copiedText {
  opacity: 0;
  padding-left: 4px;
  transition: opacity .1s linear;
  position: absolute;
}

.UuBI7q_copiedText.UuBI7q_show {
  opacity: 1;
}

.eK72_q_tabsContainer {
  margin-bottom: 20px;
  padding-top: 30px;
}

.eK72_q_tabsContainer.eK72_q_noTopPadding {
  padding-top: 0;
}

.eK72_q_tabsContainer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.eK72_q_tabsContainer ul li a {
  color: #949494;
  background: #fff;
  border: 1px solid #0000;
  border-bottom-color: #ccc;
  padding: 10px 14px;
  font-weight: 600;
  text-decoration: none;
  display: block;
  position: relative;
}

.eK72_q_tabsContainer ul li a.eK72_q_activeTab {
  opacity: 1;
  color: inherit;
  border-color: #ccc #ccc #0000;
  border-radius: 3px 3px 0 0;
}

.eK72_q_spacer {
  border-bottom: 1px solid #ccc;
  flex-grow: 1;
}

.ayo0Gq_emptyState {
  text-align: center;
  background: var(--grey-20);
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
}

._6FFkia_container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 40px;
}

._6FFkia_headerButton {
  vertical-align: middle;
  font-size: 14px;
}

._6FFkia_subheading {
  text-align: center;
  font-size: 14px;
}

._6FFkia_featureTable {
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 14px;
}

._6FFkia_featureTable ._6FFkia_labels {
  vertical-align: bottom;
}

._6FFkia_featureTable ._6FFkia_labels ._6FFkia_idColumn {
  align-self: flex-end;
  padding-right: 20px;
}

._6FFkia_featureTable ._6FFkia_labels ._6FFkia_idColumn, ._6FFkia_featureTable ._6FFkia_labels ._6FFkia_displayNameColumn {
  font-size: 18px;
  font-weight: 600;
}

._6FFkia_featureTable th {
  white-space: nowrap;
  text-align: left;
  padding: 12px 2px 6px;
  font-weight: normal;
}

._6FFkia_featureTable td {
  vertical-align: baseline;
  padding: 2px;
  font-weight: 500;
}

._6FFkia_featureTable td a {
  color: inherit;
  text-decoration: none;
}

._6FFkia_featureTable td a:hover {
  text-decoration: underline;
}

._6FFkia_featureTable td._6FFkia_idColumn {
  font-family: var(--monospace-font);
  white-space: nowrap;
}

._6FFkia_featureTable ._6FFkia_resolved {
  color: #0000004d;
  font-weight: 400;
}

._6FFkia_featureTable ._6FFkia_resolved a {
  color: inherit;
  text-decoration: line-through;
}

._6FFkia_featureTable ._6FFkia_resolved a:hover {
  text-decoration: underline line-through;
}

._6FFkia_emptyState {
  text-align: center;
  background: var(--grey-20);
  border-radius: 3px;
  padding: 10px;
  font-size: 14px;
}

.J6wpBq_RSTable {
  width: max-content;
  margin: 0 auto;
  padding: .6em;
}

.J6wpBq_RSTable td {
  padding: .6em;
}

.J6wpBq_RSTable tbody {
  font-family: var(--monospace-font);
}

.J6wpBq_RSTable tbody tr {
  border-bottom: 1px solid #ddd;
}

.J6wpBq_RSTable tbody tr:hover {
  background: #ddd;
}

.J6wpBq_RSTable thead {
  font-size: 1.1em;
  font-weight: bold;
}

._8JVzyG_container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px 40px;
}

._8JVzyG_summary, ._8JVzyG_featureSummary {
  font-size: 16px;
  line-height: 1.4;
}

._8JVzyG_feature {
  padding: 20px 0;
}

._8JVzyG_bugList {
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
}

._8JVzyG_bugList li {
  margin-bottom: 5px;
}

._8JVzyG_bugList a {
  color: inherit;
  text-decoration: none;
}

._8JVzyG_bugList a:hover {
  color: var(--blue-50);
}

._8JVzyG_resolved {
  opacity: .5;
}

._8JVzyG_resolved:before {
  content: "✓ ";
}

._8JVzyG_h3 {
  border-top: 1px solid #0003;
}

._8JVzyG_prevUrlButton {
  float: left;
}

._8JVzyG_nextUrlButton {
  float: right;
}

._8JVzyG_table table {
  border-spacing: 0;
  border-collapse: collapse;
  border: 1px solid #000;
  width: -moz-available;
}

._8JVzyG_table table th, ._8JVzyG_table table td {
  border: 1px solid #000;
  padding: 4px 6px;
}

._8JVzyG_table table th {
  vertical-align: bottom;
}

._8JVzyG_table table thead > tr {
  background-color: #e4f0f5;
}

._8JVzyG_table table thead > tr:nth-of-type(2) {
  border-bottom: 2px solid #000;
}

._8JVzyG_table table thead > tr:last-of-type > th:first-of-type {
  background-color: #e1ffe1;
}

._8JVzyG_table table thead > tr:last-of-type > th:nth-of-type(2) {
  background-color: #ffe1e1;
}

._8JVzyG_table table tbody > tr:nth-of-type(2n) {
  background-color: #edeef2;
}

._8JVzyG_table table tbody > tr > th:first-of-type {
  text-align: left;
  background-color: #e1e5f4;
}

._8JVzyG_table table tbody > tr > td:last-of-type {
  text-align: right;
}

._2OUV6G_container {
  max-width: 700px;
  margin: 0 auto;
  padding: 20px 40px;
}

._2OUV6G_list {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #ccc;
  border-radius: 3px;
  width: 100%;
  overflow: hidden;
}

._2OUV6G_list ._2OUV6G_row {
  flex-flow: row;
  align-items: center;
  padding: 10px;
  display: flex;
}

._2OUV6G_list ._2OUV6G_row:not(:last-child) {
  border-bottom: 1px solid #ccc;
}

._2OUV6G_list ._2OUV6G_col:first-child {
  white-space: nowrap;
  flex-grow: 1;
}

.OH02pa_detailsHeading {
  margin-block: 38px 13px;
}

.OH02pa_detailsHeading details {
  margin-inline: 4px;
}

.OH02pa_detailsHeading details summary {
  cursor: pointer;
  display: flex;
}

.OH02pa_detailsHeading details summary .OH02pa_summaryLabel:before {
  content: "▶︎  ";
}

.OH02pa_detailsHeading details summary .OH02pa_spacer {
  flex-grow: 1;
}

.OH02pa_detailsHeading details summary > input[type="checkbox"] {
  margin-block: 0;
}

.OH02pa_detailsHeading details[open] .OH02pa_summaryLabel:before {
  content: "▼  ";
}

/*# sourceMappingURL=index.d2f8b75d.css.map */
