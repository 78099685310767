$lightborder: hsl(0, 0%, 80%);

/* This Source Code Form is subject to the terms of the Mozilla Public
 * License, v. 2.0. If a copy of the MPL was not distributed with this
 * file, You can obtain one at http://mozilla.org/MPL/2.0/. */

/* Photon Colors SCSS Variables v3.0.1 */

$magenta-50: #ff1ad9;
$magenta-60: #ed00b5;
$magenta-70: #b5007f;
$magenta-80: #7d004f;
$magenta-90: #440027;

$purple-50: #9400ff;
$purple-60: #8000d7;
$purple-70: #6200a4;
$purple-80: #440071;
$purple-90: #25003e;

$blue-40: #45a1ff;
$blue-50: #0a84ff;
$blue-50-a30: rgba(10, 132, 255, 0.3);
$blue-60: #0060df;
$blue-70: #003eaa;
$blue-80: #002275;
$blue-90: #000f40;

$teal-50: #00feff;
$teal-60: #00c8d7;
$teal-70: #008ea4;
$teal-80: #005a71;
$teal-90: #002d3e;

$green-50: #30e60b;
$green-60: #12bc00;
$green-70: #058b00;
$green-80: #006504;
$green-90: #003706;

$yellow-50: #ffe900;
$yellow-60: #d7b600;
$yellow-70: #a47f00;
$yellow-80: #715100;
$yellow-90: #3e2800;

$red-50: #ff0039;
$red-60: #d70022;
$red-70: #a4000f;
$red-80: #5a0002;
$red-90: #3e0200;

$orange-50: #ff9400;
$orange-60: #d76e00;
$orange-70: #a44900;
$orange-80: #712b00;
$orange-90: #3e1300;

$grey-10: #f9f9fa;
$grey-20: #ededf0;
$grey-30: #d7d7db;
$grey-40: #b1b1b3;
$grey-50: #737373;
$grey-60: #4a4a4f;
$grey-70: #38383d;
$grey-80: #2a2a2e;
$grey-90: #0c0c0d;
$grey-90-a05: rgba(12, 12, 13, 0.05);
$grey-90-a10: rgba(12, 12, 13, 0.1);
$grey-90-a20: rgba(12, 12, 13, 0.2);
$grey-90-a30: rgba(12, 12, 13, 0.3);
$grey-90-a40: rgba(12, 12, 13, 0.4);
$grey-90-a50: rgba(12, 12, 13, 0.5);
$grey-90-a60: rgba(12, 12, 13, 0.6);
$grey-90-a70: rgba(12, 12, 13, 0.7);
$grey-90-a80: rgba(12, 12, 13, 0.8);
$grey-90-a90: rgba(12, 12, 13, 0.9);

$ink-70: #363959;
$ink-80: #202340;
$ink-90: #0f1126;

$white-100: #ffffff;
